import Image from "next/legacy/image";
import { useTheme } from "src/ui-kit/theme";
import { CategoriesMenuHeaderStyled } from './CategoriesMenuHeader.styles';

interface Props {
  title: string;
  imageSrc?: string;
}

const CategoriesMenuHeader = (props: Props) => {
  const { title, imageSrc } = props;
  const theme = useTheme();

  return (
    <CategoriesMenuHeaderStyled
      className='CategoriesMenuHeader'
      title={title}
      theme={theme}
    >
      <div className='CategoriesMenuHeader__title'>
        {title}
      </div>
      <div className='CategoriesMenuHeader__container'>
        {
          imageSrc && (
            <Image
              src={imageSrc}
              alt={title}
              width={44}
              height={44}
              unoptimized
            />
          )
        }
      </div>
    </CategoriesMenuHeaderStyled>
  );
};

export default CategoriesMenuHeader;
