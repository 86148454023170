import cn from 'classnames';
import Link from 'next/link';
import { useTheme } from 'src/ui-kit/theme';
import { CategoriesMenuListItemStyled } from './CategoriesMenuListItem.styles';

interface Props {
  hasChild?: boolean;
  isActive?: boolean;
  linkHref: string;
  imageUrl?: string;
  title: string;
  onMouseEnter?: () => void;
  onClick: () => void;
}

const CategoriesMenuListItem = (props: Props) => {
  const {
    hasChild,
    isActive,
    linkHref,
    imageUrl,
    title,
    onMouseEnter,
    onClick,
  } = props;

  const theme = useTheme();

  return (
    <CategoriesMenuListItemStyled
      theme={theme}
      title={title}
      className='CategoriesMenuListItem'
    >
      <Link href={linkHref} legacyBehavior>
        <a
          className={cn(
            'CategoriesMenuListItem__link', {
            'CategoriesMenuListItem__link_withChildren': hasChild,
            'CategoriesMenuListItem__link_active': isActive,
          })}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          data-status={isActive ? 'active' : null}
        >
          { imageUrl &&
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={imageUrl}
              alt={title}
              className='CategoriesMenuListItem__icon'
            />
          }
          <span className='CategoriesMenuListItem__title'>
            {title}
          </span>
        </a>
      </Link>
    </CategoriesMenuListItemStyled>
  );
};

export default CategoriesMenuListItem;
