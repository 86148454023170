import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { phone } from '../../../../styles/media';

export const CategoriesMobileMenuItemStyled = styled('li', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 57px 16px 24px;
  text-decoration: none;
  border-bottom: 1px solid ${theme.base.color.gray100};

  &.CategoriesMobileMenuItem_withChildren:after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 35px;
    top: 24px;
    border-right: 1.5px solid ${theme.base.color.gray400};
    border-bottom: 1.5px solid ${theme.base.color.gray400};
    transform: rotate(-45deg);
  }
  .CategoriesMobileMenuItem__menuItemCount {
    display: block;
    position: absolute;
    right: 35px;
    color: ${theme.base.color.gray400};
    font-size: 14px;
  }
  .CategoriesMobileMenuItem__icon {
    flex-shrink: 0;
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
  }
  .CategoriesMobileMenuItem__title {
    color: ${theme.base.color.dark};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoriesMobileMenuItem__title:first-letter {
    text-transform: uppercase;
  }

  @media ${phone} {
    font-size: 14px;
    padding: 10px 40px 10px 16px;

    .CategoriesMobileMenuItem__icon {
      margin-right: 11px;
    }
    &.CategoriesMobileMenuItem_withChildren:after {
      top: 16px;
      right: 24px;
    }
    .CategoriesMobileMenuItem__menuItemCount {
      right: 24px;
    }
  }
`);
