import cn from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import { CategoriesMenuListContainerStyled } from './CategoriesMenuListContainer.styles';

interface Props {
  onMouseLeave: () => void;
  dataMarker: string;
  categoryId?: string;
  level?: 1 | 2 | 3;
  children: ReactNode;
}

const CategoriesMenuListContainer = (props: Props) => {
  const { onMouseLeave, dataMarker, children, categoryId, level = 1 } = props;
  const containerRef = useRef<HTMLUListElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const theme = useTheme();

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollHeight, scrollTop, offsetHeight } = containerRef.current;
      const isFullScroll = scrollHeight < scrollTop + offsetHeight + 18;

      if (showScrollButton && isFullScroll) {
        setShowScrollButton(false);
      } else if (!showScrollButton && !isFullScroll) {
        setShowScrollButton(true);
      }
    }
  };

  const handleClick = () => {
    if (containerRef.current) {
      const { offsetHeight, scrollTop } = containerRef.current;
      containerRef.current.scrollTop = scrollTop + offsetHeight;
    }
  };

  useEffect(() => {
    handleScroll();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  return (
    <CategoriesMenuListContainerStyled
      theme={theme}
      className={cn({
        'CategoriesMenuListContainer_firstLevel': level === 1,
        'CategoriesMenuListContainer_secondLevel': level === 2,
        'CategoriesMenuListContainer_thirdLevel': level === 3,
      })}
    >
      <ul
        className={cn(
          'CategoriesMenuListContainer__list', {
          'CategoriesMenuListContainer__list_nested': level !== 1,
        })}
        data-testid={level === 1 ? 'categoriesMenuNav' : 'categoriesSubMenu'}
        data-marker={dataMarker}
        onMouseLeave={onMouseLeave}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {children}
        {showScrollButton &&
          <div
            onClick={handleClick}
            className={cn(
              'CategoriesMenuListContainer__scrollButton', {
              'CategoriesMenuListContainer__scrollButton_nested': level !== 1,
            })}
            role="button"
            tabIndex={0}
            data-marker="Scroll_down"
          >
            <div className='CategoriesMenuListContainer__scrollButtonIcon'/>
          </div>
        }
      </ul>
    </CategoriesMenuListContainerStyled>
  );
};

export default CategoriesMenuListContainer;
