import debounce from 'lodash/debounce';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { getCategoriesUrl } from 'src/modules/ubercatalog';
import useCategories from 'src/services/categories/useCategories';
import useLang from 'src/services/language/useLang';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Category } from '../../../../api/types';
import { getCategoryImage } from '../../helpers/getCategoryImage';
import CategoriesMenuHeader from './CategoriesMenuHeader';
import CategoriesMenuListContainer from './CategoriesMenuListContainer';
import CategoriesMenuListItem from './CategoriesMenuListItem';

type SelectCategory = Dispatch<SetStateAction<Category | null>>;


interface CategoriesMenuProps {
  onItemClick: (categoryId: string) => void;
}

const CategoriesMenu = ({ onItemClick }: CategoriesMenuProps) => {
  const categories = useCategories();
  const lang = useLang();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
  const selectCategory = useMemo(() => debounce<SelectCategory>(c => setSelectedCategory(c), 500), []);
  const selectSubCategory = useMemo(() => debounce<SelectCategory>(c => setSelectedSubCategory(c), 500), []);

  const clearSelected = () => {
    selectCategory(null);
    selectSubCategory(null);
  };

  const getItemCommonProps = (category: Category) => ({
    linkHref: getCategoriesUrl({ lang, categoryId: category.id }),
    title: category.title,
    onClick: () => onItemClick(category.id),
  });

  return (
    <StylishBox
      element='nav'
      sbs={{
        width: '0',
        overflow: 'visible',
        zIndex: '10',
        position: 'absolute',
        right: '256px',
        top: '64px',
        height: '100%',
      }}
      className='CategoriesMenu'
    >
      <CategoriesMenuListContainer
        dataMarker="First Level Categories Menu"
        onMouseLeave={clearSelected}
      >
        {categories.map(category => (
          <CategoriesMenuListItem
            key={category.id}
            {...getItemCommonProps(category)}
            hasChild={category.children.length > 0}
            isActive={selectedCategory?.id === category.id}
            imageUrl={getCategoryImage(category.image_url)}
            onMouseEnter={() => selectCategory(category)}
          />
        ))}
      </CategoriesMenuListContainer>

      {!!selectedCategory?.children.length &&
        <CategoriesMenuListContainer
          dataMarker="Second Level Categories Menu"
          categoryId={selectedCategory.id}
          onMouseLeave={clearSelected}
          level={2}
        >
          <CategoriesMenuHeader
            title={selectedCategory.title}
            imageSrc={getCategoryImage(selectedCategory.image_url)}
          />

          {selectedCategory.children.map(category => (
            <CategoriesMenuListItem
              key={category.id}
              {...getItemCommonProps(category)}
              hasChild={category.children.length > 0}
              isActive={selectedSubCategory?.id === category.id}
              onMouseEnter={() => {
                selectCategory(p => p);
                selectSubCategory(category);
              }}
            />
          ))}
        </CategoriesMenuListContainer>
      }

      {!!selectedSubCategory?.children.length &&
        <CategoriesMenuListContainer
          dataMarker="Third Level Categories Menu"
          categoryId={selectedSubCategory.id}
          onMouseLeave={clearSelected}
          level={3}
        >
          <CategoriesMenuHeader
            title={selectedSubCategory.title}
            imageSrc={getCategoryImage(selectedSubCategory.image_url)}
          />

          {selectedSubCategory.children.map(category => (
            <CategoriesMenuListItem
              key={category.id}
              {...getItemCommonProps(category)}
              onMouseEnter={() => {
                selectCategory(p => p);
                selectSubCategory(p => p);
              }}
            />
          ))}
        </CategoriesMenuListContainer>
      }
    </StylishBox>
  );
};

export default CategoriesMenu;
