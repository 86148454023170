import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { phone, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CategoriesMenuButtonStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => css(`
    position: relative;

    & .CategoriesMenuButton__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;
      flex-shrink: 0;
      height: 40px;
      padding: 2px;
      outline: none;
      border: none;
      color: ${theme.stylishLink.accented.color};
      background-color: ${theme.base.color.primaryBg};
      cursor: pointer;
      width: 210px;
      transition: .2s all ease-in-out;
    }

    & .CategoriesMenuButton__inner:hover{
      color: ${theme.stylishLink.accented.colorHover};
    }

    @media ${tablet} {
      & .CategoriesMenuButton__inner {
        width: 186px;
      }
    }

    @media ${phone} {
      & .CategoriesMenuButton__inner {
        background: none;
        width: 20px;
        height: 20px;
      }
    }
  `),
);
