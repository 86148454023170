import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme';
import { CategoriesMobileMenuItemStyled } from './CategoriesMobileMenuItem.styles';

interface Props {
  hasChildren?: boolean;
  imageUrl?: string;
  title: string;
  onClick: () => void;
  count: number;
}

const CategoriesMobileMenuItem = (props: Props) => {
  const {
    hasChildren,
    imageUrl,
    title,
    onClick,
    count,
  } = props;

  const theme = useTheme();

  return (
    <CategoriesMobileMenuItemStyled
      theme={theme}
      className={cn(
        'CategoriesMobileMenuItem', {
        'CategoriesMobileMenuItem_withChildren': hasChildren,
      })}
      onClick={onClick}
    >
      { imageUrl &&
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={imageUrl}
          alt={title}
          className="CategoriesMobileMenuItem__icon"
        />
      }
      <span className="CategoriesMobileMenuItem__title">
        {title}
      </span>

      {!hasChildren &&
        <span className="CategoriesMobileMenuItem__menuItemCount">{count}</span>
      }
    </CategoriesMobileMenuItemStyled>
  );
};

export default CategoriesMobileMenuItem;
