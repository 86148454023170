import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { phone, tablet } from '../../../../styles/media';

export const CategoriesMobileMenuStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  font-family: ${theme.base.fontFamily.normal};
  height: 100%;
  max-height: 100vh;
  width: 496px;
  z-index: 101;
  overflow-x: visible;
  overflow-y: scroll;
  scroll-behavior: smooth;

  .CategoriesMobileMenu__overlay {
    background-color: black;
    opacity: 0.3;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .CategoriesMobileMenu__scrollArrow {
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    border-bottom: 1px solid ${theme.base.color.dark};
    border-right: 1px solid ${theme.base.color.dark};
  }
  .CategoriesMobileMenu__scrollArrowBlock {
    position: fixed;
    bottom: 0;
    width: 496px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, ${theme.base.color.white}, ${theme.base.color.white}, #ffffff00);
  }
  .CategoriesMobileMenu__close {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    align-items: center;
    cursor: pointer;
    position: sticky;
    top: 0;
    background-color: ${theme.base.color.white};
    z-index: 101;
    border-bottom: 1px solid ${theme.base.color.gray100};
  }
  .CategoriesMobileMenu__back {
    display: flex;
    align-items: center;
    height: 44px;
    font-size: 16px;
    padding: 10px 24px;
    cursor: pointer;
    border-bottom: 1px solid ${theme.base.color.gray100};
  }
  .CategoriesMobileMenu__backText {
    margin-left: 16px
  }

  @media ${tablet} {
    background: ${theme.base.color.white};
  }

  @media ${phone} {
    width: 280px;
    background: ${theme.base.color.white};

    .CategoriesMobileMenu__close {
      padding: 16px 20px;
      font-size: 16px;
      line-height: 24px;
    }
    .CategoriesMobileMenu__header {
      font-size: 16px;
      padding: 16px;
    }
    .CategoriesMobileMenu__back {
      font-size: 14px;
      padding: 10px 16px;
    }
    .CategoriesMobileMenu__scrollArrow {
      width: 6px;
      height: 6px;
    }
    .CategoriesMobileMenu__scrollArrowBlock {
      width: 280px;
      height: 30px;
    }
  }
`);
