
import { Fragment, useEffect, useRef, useState } from 'react';
import PlaceModal from 'src/components/PlaceModal';
import { getCategoriesUrl } from 'src/modules/ubercatalog';
import useCategories from 'src/services/categories/useCategories';
import useLang from 'src/services/language/useLang';
import { useTheme } from 'src/ui-kit/theme';
import { Typography } from 'src/ui-kit/Typography';
import { Category } from '../../../../api/types';
import { useLocalize } from '../../../../services/localize/useLocalize';
import IconFont from '../../../../ui-kit/IconFont';
import { getCategoryImage } from '../../helpers/getCategoryImage';
import { CategoriesMobileMenuStyled } from './CategoriesMobileMenu.styles';
import CategoriesMobileMenuItem from './CategoriesMobileMenuItem';
import { useRouter } from 'next/navigation';

interface Props {
  onClose(): void;
  onItemClick(categoryId: string): void;
}

const CategoriesMobileMenu = (props: Props) => {
  const { onClose, onItemClick } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const localize = useLocalize();
  const lang = useLang();
  const categories = useCategories();
  const router = useRouter();
  const theme = useTheme();
  const [isScrollArrowDisplayed, setIsScrollArrowDisplayed] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const isMain = !selectedCategories.length;
  const currentCategory = selectedCategories[selectedCategories.length - 1];
  const categoriesToDisplay = isMain
    ? categories
    : currentCategory.children;

  const handleScrollArrowClick = () => {
    if (containerRef.current) {
      const { clientHeight, scrollHeight } = containerRef.current;
      containerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      const canScrollDown = (scrollTop + clientHeight) < scrollHeight;
      setIsScrollArrowDisplayed(canScrollDown);
    }
  };

  const goToCategory = (categoryId: string) => {
    onItemClick(categoryId);
    const href = getCategoriesUrl({ lang, categoryId });
    router?.push(href);
  };

  const handleClickItem = (category: Category) => {
    if (category.children.length) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      goToCategory(category.id);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [selectedCategories]);

  return (
    <PlaceModal
      isOpen
      onClose={onClose}
      overlayStyles={{ zIndex: theme.base.zIndex.modalOverlay }}
    >
      <CategoriesMobileMenuStyled
        theme={theme}
        className='CategoriesMobileMenu'
        data-testid='categoriesMobileMenu'
        data-marker="Content Menu mobile"
        onScroll={handleScroll}
        ref={containerRef}
      >
        <div
          className='CategoriesMobileMenu__close'
          data-testid='menuHeader'
          onClick={onClose}
        >
          <IconFont name='close' size='16px' sbs={{ mr: '16px' }} />
          <Typography variant="h5">{localize('catalog')}</Typography>
        </div>

        {!isMain &&
          <Fragment>
            <div
              className='CategoriesMobileMenu__back'
              data-testid='menuBackButton'
              onClick={() => setSelectedCategories(selectedCategories.slice(0, selectedCategories.length - 1))}
            >
              <IconFont name='arrow-back' />
              <span className="CategoriesMobileMenu__backText">{currentCategory.title}</span>
            </div>
            <CategoriesMobileMenuItem
              title={localize('categories.view_all')}
              onClick={() => goToCategory(currentCategory.id)}
              count={currentCategory.count}
            />
          </Fragment>
        }

        {categoriesToDisplay.map(category => {
          return (
            <CategoriesMobileMenuItem
              key={category.id}
              hasChildren={!!category.children.length}
              imageUrl={isMain ? getCategoryImage(category.image_url) : undefined}
              title={category.title}
              onClick={() => handleClickItem(category)}
              count={category.count}
            />
          );
        })}

        {isScrollArrowDisplayed &&
          <div
            className='CategoriesMobileMenu__scrollArrowBlock'
            onClick={handleScrollArrowClick}
            data-testid='scrollArrow'
          >
            <div className='CategoriesMobileMenu__scrollArrow' />
          </div>
        }
      </CategoriesMobileMenuStyled>
    </PlaceModal>
  );
};

export default CategoriesMobileMenu;
