import { CSSProperties, ReactNode } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import GeneralModal from '../GeneralModal/GeneralModal';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  overlayStyles: CSSProperties;
  position?: 'left' | 'right';
}

const PlaceModal = (props: Props) => {
  const {
    children,
    position = 'left',
    onClose,
    isOpen,
    overlayStyles,
  } = props;

  const theme = useTheme();

  const positionStyles = {
    left: {
      alignItems: 'flex-start',
    },
    right: {
      alignItems: 'flex-end',
    },
  };

  const customStyles: {
    [key: string]: CSSProperties;
  } = {
    content: {
      padding: 0,
      top: 0,
      bottom: 0,
      left: 'auto',
      right: 'auto',
      borderRadius: 0,
      border: 'none',
      flexGrow: 1,
    },
    overlay: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(51,51,51,0.45)',
      overflowY: 'auto',
      zIndex: theme.base.zIndex.modalOverlay,
      ...positionStyles[position],
      ...overlayStyles,
    },
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      contentStyles={customStyles.content}
      overlayStyles={customStyles.overlay}
    >
      {children}
    </GeneralModal>
  );
};

export default PlaceModal;
