import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CategoriesMenuListContainerStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  &.CategoriesMenuListContainer_firstLevel {
    position: sticky;
    max-height: calc(100vh - 186px);
    overflow: visible;
    width: 256px;
  }
  &.CategoriesMenuListContainer_secondLevel {
    position: absolute;
    left: 258px;
    top: 0px;
    height: 100%;
    z-index: 10;
    margin-bottom: 24px;
  }
  &.CategoriesMenuListContainer_thirdLevel {
    position: absolute;
    left: 516px;
    top: 0px;
    height: 100%;
    z-index: 10;
  }
  .CategoriesMenuListContainer__list {
    position: sticky;
    width: 256px;
    background-color: white;
    max-height: calc(100vh - 88px);
    padding-bottom: 12px;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0 3px 8px #00000020;
    scroll-behavior: smooth;
  }
  .CategoriesMenuListContainer_firstLevel::before,
  .CategoriesMenuListContainer_firstLevel::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
  }
  .CategoriesMenuListContainer_firstLevel::before {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #0000001a transparent;
    top: -6px;
    right: 75px;
  }
  .CategoriesMenuListContainer_firstLevel::after {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
    top: -5px;
    right: 76px;
  }
  .CategoriesMenuListContainer__list_nested {
    max-height: calc(100vh - 88px);
    padding: 0;
  }
  .CategoriesMenuListContainer__scrollButton {
    z-index: 2;
    height: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    background-color: white;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 256px;
    height: 0;
    transform: translate(0, 12px);
  }
  .CategoriesMenuListContainer__scrollButton_nested {
    bottom: 12px;
  }
  .CategoriesMenuListContainer__scrollButton:before {
    content: '';
    position: absolute;
    display: block;
    bottom: 19px;
    height: 30px;
    pointer-events: none;
    width: 256px;
    background: linear-gradient(to top, white, #ffffff00);
  }
  .CategoriesMenuListContainer__scrollButton:after {
    content: "";
    position: absolute;
    bottom: 0;
    font-size: 8px;
    width: 256px;
    height: 20px;
    text-align: center;
    background: white;
  }
  .CategoriesMenuListContainer__scrollButtonIcon:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    position: absolute;
    left: 136px;
    top: -16px;
    border-left: 1.5px solid ${theme.base.color.dark};
    border-bottom: 1.5px solid ${theme.base.color.dark};
    transform: rotate(-45deg);
    z-index: 1;
  }
`);
